<template>
  <div class="page-height" style="padding-bottom: 20px">
    <div class="flex-center industrial-top">
      <div class="w1200">
        <div class="page-title">产业体系</div>
        <div class="box-info">
          生猪大数据智云脑数整合上游、中游、下游资源，旨在通过数字化手段促进生猪产业全链条的协同与高效运作。具体而言，这一过程涵盖了从饲料供应、生猪养殖到屠宰加工及市场销售的各个环节，通过数据驱动实现资源的优化配置和产业的智能化升级。
        </div>
      </div>
    </div>
    <div class="flex-center">
      <div id="industrialChart" class="w1200 chart-page box"></div>
    </div>
  </div>
</template>


<script>
import G6 from '../assets/js/antV/g6.min.js'
import {ServiceGetjson} from "@/assets/js/api";

export default {
  name: "IndustrialSystem",
  mounted() {
    this.initChart()
  },
  methods: {
    initChart() {
      ServiceGetjson().then(res=>{
        console.log(res)
      })
      const container = document.getElementById('industrialChart');
      const width = container.scrollWidth;
      const height = container.scrollHeight || 500;
      const graph = new G6.Graph({
        container: 'industrialChart',
        width,
        height,
        layout: {
          type: 'force',
          linkDistance:120,
        },
        modes: {
          default: ['drag-canvas', 'activate-relations',
            {
              type: 'zoom-canvas'
            }],
        },
        defaultNode: {

          style: {
            opacity: 0.5
          }
        },
        defaultEdge: {
          /* style for the keyShape */
          style: {
            lineAppendWidth: 10,
            opacity: 1,
            // stroke: '#2479F0',
          }
        },
        nodeStateStyles: {
          highlight: {
            opacity: 1,
          },
          dark: {
            opacity: 0.2,
          },
        },
        edgeStateStyles: {
          highlight: {
            stroke: '#2479F0',
          },
        },
      });


      fetch('data.json')
          .then(response => response.json())
          .then(obj => {
            const data = obj;
            data.nodes.forEach((node)=>{
              node.label = node.name.length > 6 ? node.name.substring(0,7) + '...' : node.name
              let colors = [
                '#828BF4',
                '#808FF4',
                '#7997F4',
                '#739EF4',
                '#6DA4F3',
                '#63ACF3',
                '#5BB2F3',
              ]
              // if(node.id.length == 1) node.type = 'background-animate'
              node.color = colors[node.id.length - 1]
              node.style = {
                fill: colors[node.id.length - 1],
              };
              node.size = 140 - node.id.length * 10
              node.labelCfg = {
                style: {
                  fontSize: (7 - node.id.length) * 2  + 12,
                  fill: '#444',
                  fontWeight: 400,
                },
              };
            })
            graph.data(data);
            graph.render();
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });



      graph.on('node:dragstart', function (e) {
        graph.layout();
        refreshDragedNodePosition(e);
      });
      graph.on('node:drag', function (e) {
        refreshDragedNodePosition(e);
      });
      graph.on('node:dragend', function (e) {
        e.item.get('model').fx = null;
        e.item.get('model').fy = null;
      });

      function clearAllStats() {
        graph.setAutoPaint(false);
        graph.getNodes().forEach(function (node) {
          graph.clearItemStates(node);
        });
        graph.getEdges().forEach(function (edge) {
          graph.clearItemStates(edge);
        });
        graph.paint();
        graph.setAutoPaint(true);
      }

      graph.on('node:mouseenter', function (e) {
        const item = e.item;
        graph.setAutoPaint(false);
        graph.getNodes().forEach(function (node) {
          graph.clearItemStates(node);
          graph.setItemState(node, 'dark', true);
        });
        graph.setItemState(item, 'dark', false);
        graph.setItemState(item, 'highlight', true);
        graph.getEdges().forEach(function (edge) {
          if (edge.getSource() === item) {
            graph.setItemState(edge.getTarget(), 'dark', false);
            graph.setItemState(edge.getTarget(), 'highlight', true);
            graph.setItemState(edge, 'highlight', true);
            edge.toFront();
          } else if (edge.getTarget() === item) {
            graph.setItemState(edge.getSource(), 'dark', false);
            graph.setItemState(edge.getSource(), 'highlight', true);
            graph.setItemState(edge, 'highlight', true);
            edge.toFront();
          } else {
            graph.setItemState(edge, 'highlight', false);
          }
        });
        graph.paint();
        graph.setAutoPaint(true);
      });
      graph.on('node:mouseleave', clearAllStats);
      graph.on('canvas:click', clearAllStats);

      if (typeof window !== 'undefined')
        window.onresize = () => {
          if (!graph || graph.get('destroyed')) return;
          if (!container || !container.scrollWidth || !container.scrollHeight) return;
          graph.changeSize(container.scrollWidth, container.scrollHeight);
        };
      function  refreshDragedNodePosition(e) {
        const model = e.item.get('model');
        model.fx = e.x;
        model.fy = e.y;
      }

    },

  }
}
</script>

<style scoped>
@import "../assets/css/industrial.css";
</style>
